import React from 'react'
import './loading.css'; 

const Loader = () => {
  return (
    <div id='outer'>
        <div id='middle'>
        <div id='inner'></div>
        </div>
    </div>
  )
}

export default Loader